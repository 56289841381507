import React from 'react';
import { Terminal } from 'lucide-react';

const HeroTitle = () => (
  <div className="hero-title text-left max-w-lg">
    <div className="flex items-center mb-6">
      <Terminal size={64} className="text-accent-primary mr-4" /> {/* Increased Icon Size */}
      <h1 className="text-6xl font-extrabold text-text-primary leading-tight">
        AI for Low-Level Programming
      </h1>
    </div>
    <p className="text-lg text-text-secondary mb-6 leading-relaxed">
      A Fine-Tuned LLM for Developers, with Complete Anonymity via Oblivious HTTP.
    </p>
  </div>
);

export default HeroTitle;
