import React from 'react';
import HeroTitle from './HeroTitle';
import CodeTerminal from './CodeTerminal';
import GetEarlyAccessButton from './GetEarlyAccessButton';

const HeroSection = () => (
  <section id="home" className="hero-section flex flex-col items-center justify-center bg-bg-primary py-28 px-4 min-h-screen">
    {/* Main content wrapper */}
    <div className="content-wrapper w-full max-w-6xl flex flex-col items-center">
      {/* Wrapper with offset background */}
      <div className="offset-background bg-offset-background p-10 rounded-lg shadow-lg w-full">
        <div className="flex flex-col md:flex-row items-center justify-between w-full">
          <div className="hero-title-wrapper w-full md:w-1/2 mb-10 md:mb-0">
            <HeroTitle />
          </div>
          <div className="flex-grow w-full md:w-1/2 md:max-w-lg md:ml-12 mt-8 md:mt-0">
            <CodeTerminal />
          </div>
        </div>
      </div>

      {/* email form is below and centered */}
      <div className="mt-12 w-full max-w-md mx-auto">
        <GetEarlyAccessButton />
      </div>
    </div>
  </section>
);

export default HeroSection;
