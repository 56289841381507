import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProfessionalDevLLMLandingPage from './ProfessionalDevLLMLandingPage';
import VerifyEmail from './components/VerifyEmail';

function App() {
  return (
    <Router>
      <Routes>
        {/* Landing page route */}
        <Route path="/" element={<ProfessionalDevLLMLandingPage />} />

        {/* Email verification route */}
        <Route path="/verify" element={<VerifyEmail />} />
      </Routes>
    </Router>
  );
}

export default App;

