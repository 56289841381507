import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const VerifyEmail = () => {
  const [status, setStatus] = useState('Verifying...');
  const location = useLocation();

  // Extract email and token from the URL query parameters
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');
  const token = searchParams.get('token');

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await fetch(`https://jsuk2sk6ih.execute-api.us-east-2.amazonaws.com/prod/verify?email=${email}&token=${token}`);
        if (response.ok) {
          const data = await response.json();
          setStatus('Email verified successfully!');
        } else {
          setStatus('Failed to verify email. The token might be invalid or expired.');
        }
      } catch (error) {
        setStatus('Error occurred during verification.');
      }
    };

    if (email && token) {
      verifyEmail();
    } else {
      setStatus('Invalid verification link.');
    }
  }, [email, token]);

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>{status}</h1>
    </div>
  );
};

export default VerifyEmail;

